<template>
  <v-container>

    <!-- Section intro -->
    <section id="intro">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Le comité inclusivité
            </h1>
          </v-card-title>
          <v-card-text class="body-1">
            <p class="text--primary">
              Le comité inclusivité est un groupe d'ancien•e•s élèves de l'école visant à prévenir et limiter les
              comportements
              discriminatoires auxquels peuvent être confrontés les élèves et ingénieur•e•s de SIGMA Clermont.
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section objectifs -->
    <section id="objectifs">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Nos objectifs
            </h1>
          </v-card-title>
          <v-card-text class="body-1">
            <p class="text--primary">
              Sensibiliser et informer les alumni de l'AISC sur les discriminations
              et les violences
            </p>
            <p class="text--primary">
              Soutenir les institutions en place à SIGMA Clermont (direction, BDE, ...)
              dans la mise en place et le maintien des dispositifs de prévention
              et d'action contre les discriminations.
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section moyens -->
    <section id="moyens">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Nos moyens
            </h1>
          </v-card-title>
          <v-card-text class="body-1">
            <p class="text--primary">
              Création et diffusion de contenu de sensiblisation :
            <ul>
              <li>Articles à thèmes</li>
              <li>Infographies</li>
              <li>Posters</li>
              <li>Sondages</li>
              <li>Masterclass</li>
              <li>...</li>
            </ul>
            </p>
            <p class="text--primary">
              Soutien, échanges, partage de connaissances et d'idées...
            </p>

            <p align="center">
              <router-link to="/livret-inclusivite"><v-img :src="require('../assets/img/mockup_livret.png')" width="50%" height="1%"></v-img></router-link>
              <router-link to="/livret-inclusivite">Le livret inclusivité, réalisation du comité 2023</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section qui-sommes-nous -->
    <section id="qui-sommes-nous">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Qui sommes-nous ?
            </h1>
          </v-card-title>
          <v-layout column wrap class="pb-4 card-text" align-center>
            <v-container grid-list-md text-xs-center>
              <v-layout align-center justify-center fill-height>
                <v-flex xs12 md12>
                  <v-avatar size="140">
                    <v-img :src="require('../assets/img/baptiste.png')"></v-img>
                  </v-avatar>
                  <h2>Baptiste</h2>
                  <h4>IFMA Promo XXIV - MMS </h4>
                  <span class="subheading">
                    Je suis chef de projet dans le domaine de la conception et la réalisation de machines spéciales.
                    <br />
                    Mon engagement dans la lutte contre les discriminations a commencé il y a 3 ans. Peu de temps après
                    avoir réalisé que le monde professionnel pouvait encore en 2020 être rempli de haine et de préjugés,
                    j’ai décidé de m’engager dans l’associatif.
                    Depuis, j'interviens une fois par mois, en milieu scolaire et en entreprise pour sensibiliser au
                    sexisme et aux discriminations LGBTQUIA+phobes.
                    <br />
                    Après un passage de deux ans au sein du CA et du bureau de l’AISC, j’ai rejoint le comité inclusivité
                    pour étendre les actions que je réalise avec mon association en l'étendant au contexte de l’AISC.
                    <br />
                    Objectif : faire de chaque Sigmalien des ingénieur.e.s responsable.e.s !

                  </span>
                  <br />
                </v-flex>
                <v-flex xs12 md12>
                  <v-avatar size="140">
                    <v-img :src="require('../assets/img/delphine.png')"></v-img>
                  </v-avatar>
                  <h2>Delphine</h2>
                  <h4>IFMA 2002 - SPA</h4>
                  <span class="subheading">
                    Je suis Responsable R&D emballage dans un grand groupe industriel du nord de la France où je travaille
                    depuis 19 ans.
                    De part mon parcours professionnel, j’ai été la 2ème femme chef d’équipe 5x8 de mon entreprise, et la
                    situation de mon entreprise, nous sommes 13% de femmes, je me suis très tôt intéressée à la place des
                    femmes dans l’entreprise et comment les rendre plus visibles.
                    Je suis également membre du réseau Femmes Ingénieures depuis plus de 10 ans et référente harcèlement
                    dans mon entreprise.
                    <br />
                    J’ai décidé de rejoindre le comité Inclusivité de l’AISC pour pouvoir partager mon expérience avec les
                    alumnis et soutenir les projets qui peuvent se créer à SIGMA Clermont ou à l’AISC autour de cette
                    thématique qui m’est chère.

                  </span>
                  <br />
                </v-flex>
                <v-flex xs12 md12>
                  <v-avatar size="140">
                    <v-img :src="require('../assets/img/lisa.png')"></v-img>
                  </v-avatar>
                  <h2>Lisa</h2>
                  <h4>SIGMA Promo I - MHP</h4>
                  <span class="subheading">
                    Je suis doctorante en micro et nano-électronique à l’université d’Aix-Marseille.
                    <br />
                    Au cours de mes années à Sigma et lors de mes différents stages j’ai été confrontée à de nombreuses
                    situations qui m’ont mis mal à l’aise. Ma première expérience professionnelle m’a
                    fait me rendre compte que le problème allait bien au-delà du milieu étudiant et que les comportements
                    problématiques que j’observais à l’école étaient les mêmes que ceux qui avaient lieu dans le milieu
                    professionnel.
                    C’est de ce constat que j’ai décidé de m’entourer de personnes motivées pour faire bouger les choses
                    pour les étudiant·e·s et les ingénieur·e·s de Sigma. De là est né le Comité Inclusivité.
                  </span>
                  <br />
                </v-flex>
                <v-flex xs12 md12>
                  <v-avatar size="140">
                    <v-img :src="require('../assets/img/noemie.png')"></v-img>
                  </v-avatar>
                  <h2>Noémie</h2>
                  <h4>SIGMA Promo I - MMS</h4>
                  <span class="subheading">
                    Je suis Cheffe de projet numérique.
                    <br />
                    Diplômée de SIGMA Clermont (Mécanique) en 2020, j’aime m’investir dans l’associatif pour accompagner
                    les étudiants·es et diplômés·ées dans leur évolution. Féministe convaincue, et forte de mon expérience
                    de femme dans de nombreux contextes et études majoritairement masculins, j’accorde un intérêt
                    particulier à l’inclusion de toutes les minorités, notamment dans les milieux professionnels.
                    <br />
                    Ainsi, j’ai pu écrire mon mémoire de Master en Management sur les Emplois des Femmes dans l’Industrie
                    Automobile ; vous pouvez le retrouver et le lire dans l’onglet Ressources de cette page.
                    Mes expériences associatives incluent également depuis 10 ans le bénévolat au profit du festival Rock
                    21 Albi qui vise à promouvoir la journée mondiale de la trisomie 21 en lui associant une image
                    festive, dynamique et positive ; ainsi qu’au sein de plusieurs associations de prévention et réduction
                    des risques en milieux festifs, à destination des jeunes et des consommateurs·rices de toutes
                    catégories sociales.
                    <br />
                    Aujourd’hui, en plus d’être élue du comité de l’AISC en tant que chargée de communication, il était
                    évident pour moi que ma place se trouvait également au sein du Comité Inclusivité, dont les valeurs et
                    les actions sont en accord avec ma soif de justice sociale.

                  </span>
                  <br />
                </v-flex>
              </v-layout>
            </v-container>
          </v-layout>
        </v-card>
      </v-layout>
    </section>

  </v-container>
</template>

<script>
export default {
  name: 'Home',

  data: () => ({

  }),
}
</script>
